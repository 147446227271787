
                    @import "@/css/ajax-rendering/admin-lte/_variables.scss";
                
@import 'variables';
@import 'mixins';

.CustomerCenterContainer {
    @include bootstrapContainer();
    padding: 0;
}

.CustomerCenterContainer a.ReturnDisabled:hover {
    text-decoration: none !important;
}


.CustomerCenterContainer a {
    color: $textColor;
}

.CustomerCenterContainer .bold {
    font-weight: bold;
}

.FolderMenuPosition td {
    @include toSizeSM() {
        width: 100% !important;
        display: block;
    }

    @include fromSizeSM() {
        width: 12% !important;

        &:last-child {
            width: auto !important;
        }
    }
}

.FolderMenuPosition a:hover.FolderMenu {
    background-color: #ffffff;
}



.FolderMenuPosition .FolderMenuLastElement {
    @include fromSizeSM {
        border-left: solid 1px #d8d6ce;
        border-bottom: 1px solid #d8d6ce;
    }
}

.FolderMenuPosition .FolderMenu {
    text-decoration: none;
    cursor: pointer;
    padding: 12px;
    text-align: center;
    float: left;
    border-bottom: solid 1px #d8d6ce;
    font-weight: bold;
    width: 100%;

    @include fromSizeSM {
        padding: 5px;
        border-left: solid 1px #d8d6ce;
        border-top: solid 1px #d8d6ce;
        background-color: #ebeae5;
    }
}

.FolderMenuPosition a:link.FolderMenuSelected2,
.FolderMenuPosition a:visited.FolderMenuSelected2 {
    background-color: #efefef;

    @include fromSizeSM() {
        border-bottom: solid 1px #ffffff;
        background-color: #ffffff;
    }
}

.KundeSenterBox {
    overflow: hidden;

    @include fromSizeSM() {
        border-left: 1px solid #d8d6ce;
        border-right: 1px solid #d8d6ce;
        border-bottom: 1px solid #d8d6ce;
        padding: 10px;
    }

    .kundesenter-format {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    @include toSizeSM() {
        .StatusInfo {
            text-align: center;
            display: inline-block;
            padding: 15px 10px;
        }
    }
}

.KundeSenterBox .container {
    width: 100%;
}

.TableOrdreHisSubTable .MyPage {
    padding: 5px 0px 45px 0px;
}

.TableOrdreHisSubTable {
    border-left: 1px solid #d8d6ce;
    border-right: 1px solid #d8d6ce;
    border-bottom: 1px solid #d8d6ce;
    border-top: Solid 1px #d8d6ce;
}

.MinSideToppInfo {
    border: Solid 1px #d8d6ce;
    background-color: #ebeae5;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 0px 0px;
}

.MinSideToppInfoTxt {
    font-weight: normal;
    padding: 0;
    margin: 0;
}


.PageHeading {
    display: none;

    @include fromSizeSM() {
        display: inline;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }
}

.FieldsetLegend {
    padding: 0;
    font-weight: bold;
}

.MinSideTD {
    font-weight: normal;
    padding: 10px;
    margin: 0;

    @include toSizeSM() {
        padding: 0;
    }
}

.MinSideTD .CheckBox, .MinSideTD .Button {
    float: left;
    clear: both;
}

.MinSideTDSpacer {
    font-weight: normal;
    padding: 4px 10px 4px 10px;
    margin: 0;

    @include toSizeSM() {
        width: 100% !important;
        display: block;
    }
}

.PdfLink:link, .PdfLink:visited, .PdfLink:active {
    display: block;
    width: 30px;
    height: 14px;
    text-align: center;
    color: #ffffff;
    background-color: #C7532C;
    text-decoration: none;
    border: solid 1px #333333;
    padding: 1px 0px 1px 0px;
}

.PdfLink:hover {
    background-color: #ffffff;
    color: #C7532C;
    border: solid 1px #C7532C;
}

.Kunderegistrering .CheckBox {
    font-weight: bold;
}


/* Kundesenter meny */
.KundesenterMenyLink:hover {
    text-decoration: underline;
}

/* Kundesenter meny m/faneblader */
div.Kundesentermeny {
    float: left;
    width: 100%;
    background: #DAE0D2;
    line-height: normal;
}

div.Kundesentermeny ul {
    margin: 0px 0px 0px 0px;
    padding: 10px 10px 0px 0px;
    list-style: none;
}

div.Kundesentermeny li.notSelected {
    float: left;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 9px;
}

div.Kundesentermeny a {
    float: left;
    display: block;
    padding: 5px 15px 4px 6px;
    text-decoration: none;
    font-weight: normal;
}

div.Kundesentermeny #current {
    border-width: 0px;
}

div.Kundesentermeny #current a {
    padding-bottom: 5px;
}

div.Kundesentermeny li.selected {
    float: left;
    border-width: 0px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 9px;
}

div.Kundesentermeny a.selected {
    float: left;
    display: block;
    padding: 5px 15px 4px 6px;
    text-decoration: none;
    font-weight: bold;
}

.DeleteMyAccount {
    display: inline;
}

/* Kundesenter undermeny */
.KundesenterUL {
    border-bottom: 1px solid #D8D6CE;
    margin: 5px 5px 15px 0px;
    overflow: auto;
    list-style-type: none;
    padding-bottom: 5px;
    width: 99%;
}

.KundesenterUL LI {
    float: left;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 156px;
}

.KundesenterUL LI a {
    font-weight: bold;
    color: #62a0c5;
    text-decoration: none;
    padding-bottom: 5px;
}

.KundesenterSubUL {
    border-bottom: 1px solid #D8D6CE;
    margin: 0px 5px 15px 0px;
    overflow: auto;
    list-style-type: disc;
    padding-bottom: 10px;
    padding-left: 15px;
    width: 97%;
}

.KundesenterSubUL LI {
    float: none;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    width: 175px;
}

.KundesenterSubUL LI a {
    font-weight: bold;
    color: #62a0c5;
    text-decoration: none;
    padding-bottom: 5px;
}

.CustomerCenterContainer a.cust-login-link {
    display: block;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    text-align: center;

    @include fromSizeSM {
        padding-top: 15px;
    }
}


/* =Customer center Page
-----------------------------------------------------------------------------*/
.CustomerCenterContainer {
    margin-top: -25px;

    @include toSizeSM() {
        h2,
        .h2 {
            font-size: 15px;
        }
    }

    @include fromSizeSM() {
        margin-top: 0;
        padding: 20px 0;
    }
}

.Label_txt {
    font-weight: bold;
}

.CustomerCenterContainer .KundeSenterBox {
    background: #fff;
    margin-top: -15px;

    @include toSizeSM() {

        .TableInTable .DropDownList {
            margin-bottom: 15px;
            width: 100%;
            padding: 0;
        }

        .TableInTable .TextBoxKundedata {
            margin-bottom: 20px;
            width: 100%;
        }

        .TableInTable .DropDownList select {
            height: 30px;
        }

        .cust-s-space {
            display: none;
        }

        .cust-s-content {

            .MenuCustomerInfo {
                border-radius: 3px 3px 0 0;
            }

            .MenuShowPriceList {
                border-radius: 0 0 3px 3px;
            }

            a {
                padding: 10px 12px;
                background: #eee;
                color: #434244;
                margin: 0 -5px;
                display: block;
                text-decoration: none;
                font-weight: bold;
            }

            a:last-child {
                border-bottom: none;
            }
        }

        .MenuCustomerInfo,
        a.LinkKundesenter {
            padding: 10px 12px;
            background: #3399cc;
            color: #fff;
            border-bottom: 1px solid #fff;
            margin: 0 -5px;
            display: block;
            text-decoration: none;
            font-weight: normal;
        }

        .DeleteMyAccount {
            text-decoration: none;
        }
        /* RS2016: Remove when edit account css exist for mobile */
        .MenuEditAccount {
            display: none !important;
        }


        table {
            border: 1px solid #eee;
            padding: 5px;
        }

        table.TableInTable,
        .TableInTable table,
        .TableOrdreHis,
        .cust-i {
            border-width: 0;
        }

        .TableOrdreHis {
            padding: 0 15px;
            display: block;
            width: 100%;

            tbody, tr, td {
                display: block;
                width: 100%;
            }
            /* IKKE FERDIG - KUN SKJULT */
            tr {
                &.RowFakturaListe,
                &.RowPakkesporListe,
                &.RowKjopteProdukterListe,
                &.RowSpecialOffersListe,
                &.RowKreditnotaListe,
                &.RowRestordreListe {
                    display: none;
                }
            }
        }

        .TableInTable .btn-default {
            width: 100%;
        }

        table table {
            width: 100%;
        }

        .MinSideTD,
        .MinSideTD table,
        .MinSideTD table tbody,
        .MinSideTD table tbody tr,
        .MinSideTD table tbody td {
            width: 100% !important;
            display: block;
            padding: 0;
        }

        .MinSideTD table tbody td {
            padding: 0 10px;
        }
    }

    @include fromSizeSM() {
        margin-top: 0;
    }
}

.CustomerCenterContainer .KundeSenterBox .KundesenterUL div {
    float: left;
}




/* = Tabell for Min side
--------------------------------------------------------------------------------------------------------------------*/
.CustomerCenterContainer .discount-code {
    width: auto;
    display: inline-block;
    vertical-align: top;
}

.CustomerCenterContainer {
    .cust-i-myp-td3 {
        .checkbox {
            display: inline-block;
            width: 100%;
            font-size: 16px;
            padding: 0 30px;
            margin-bottom: 0;
        }

        .checkbox.inline label {
            padding-left: 22px;
        }

        .checkbox.inline label input {
            margin-left: -42px;
        }
    }
}

@include toSizeSM() {

    .CustomerCenterContainer {
        .cust-i-top-1 {
            padding-top: 0 !important;
        }

        .cust-i-no {

            .Label_txt {
                padding-right: 10px;
            }
        }

        .cust-i-bet {

            .cust-i-bet-td6 {
                a.btn {
                    color: #337ab7;
                    margin: 0;
                    padding: 0;
                    background: none;
                    border: none;
                    display: none;
                }

                a img {
                    display: none;
                }
            }
        }

        .cust-i-no,
        .cust-i-bil-td1,
        .cust-i-bil-td2,
        .cust-i-ship-td1,
        .cust-i-ship-td2 {
            background: #ebeae5;
        }

        .cust-i-bil-td2,
        .cust-i-ship-td2 {
            display: none !important;
        }

        .cust-i-bil-td1,
        .cust-i-ship-td1 {
            padding: 10px 5px;
            font-size: 16px;
        }

        .cust-i-bil-td8,
        .cust-i-ship-td11 {
            font-weight: bold;
        }

        .cust-i-td9 {
            padding-right: 10px;
            margin-top: 15px;

            input {
                display: block;
                margin-top: 5px;
                margin-bottom: 10px;
                width: 100%;
            }

            a {
                display: none;
            }

            .bold {
                font-weight: bold;
            }
        }

        .cust-i-myp {
            min-height: 200px;
        }

        .cust-i-myp-td2 {
            a {
                display: none;
            }
        }

        .cust-i-ship {
            margin-top: 15px;
        }

        .cust-i-myp-td3 .btn-default {
            width: 100%;
        }

        .MinSideTDSpacer.cust-i-td3 {
            display: none;
        }
    }
}

/* = Tabell for Ordrehistorikk
--------------------------------------------------------------------------------------------------------------------*/
.TableOrdreHis tr {
    vertical-align: top;
}

.TableOrdreHis td.MyPage {
    @include fromSizeSM() {
        padding: 25px 0px 45px 0px;
    }
}

td.MyPage {
    @include fromSizeSM() {
        padding: 45px 0px 45px 0px;
    }
}

.TableInTable {
    padding: 0px 0px 0px 0px;
    margin: 5px 0px 0px 0px;
}

.TableInTable tr {
    vertical-align: top;
}

.TableInTable td {
    @include toSizeSM() {
        width: 100%;
        display: inline-block;
    }

    @include fromSizeSM() {
        padding: 0px 10px 0px 0px;
        text-align: left;
    }
}

.TableInTable .DropDownList {
    padding: 1px 10px 0px 0px;
}

.TableInTable .EmptyTD {
    height: 20px;
}

/* Tabell for Ordredetalj */
.OrdreDetalj {
    width: 100%;
    border-left: solid 1px #d8d6ce;
    border-top: solid 1px #d8d6ce;
    border-bottom: Solid 1px #d8d6ce;
}

td#tdOrdrehodeLevering span.Block {
    display: block;
}

.OrdreDetaljHeadTD {
    vertical-align: top;
    background-color: #d8d6ce;
    padding: 5px 10px 0px 10px;
}

.OrdreDetaljTD {
    vertical-align: top;
    padding: 10px 10px 10px 10px;
    border-right: Solid 1px #d8d6ce;
}

/* Tabell for Ordredetalj listingen */
.OrdrelinjeHead {
    vertical-align: top;
    background-color: #d8d6ce;
    padding: 5px 0px 5px 0px;
}

.Ordrelinje {
    vertical-align: top;
    padding: 8px 5px 8px 0px;
}

/* Tabell for Ordredetalj TOTAL OPPSUMMERING */
.OrdreTotalTabell {
    padding-top: 5px;
    padding-bottom: 5px;
}

.OrdreTotalTabellSpes {
    padding-top: 5px;
    padding-bottom: 5px;
}

.OrdreTotalTabellUnderline {
    border-bottom: Solid 1px #d8d6ce;
}

.OrdreTotalTabellBorderTop {  border-top: Solid 1px #d8d6ce;}

.OrdreTotalTabellUnderlineRd {
    border-bottom: Solid 1px #c7532c;
}

.ordrestatus-sum {
    min-width: 300px;

    & .ordrestatus-sum-total {
        width: 35%;
    }
}
/* Ordrestatus detaljsiden */
.LevDatoBlueTxt {
    color: blue;
}

.LevDatoGreenTxt {
    color: Green;
}

.LevDatoRedTxt {
    color: Red;
}

a.LevDatoBlueTxt {
    color: blue;
}

a.LevDatoGreenTxt {
    color: Green;
}

a.LevDatoRedTxt {
    color: Red;
}

/* Filtreringsramme */
.FilterOrder {
    padding: 10px 10px 10px 10px;
}

/* Tilbudstatus detaljesiden */
.TilbudStatusRedText {
    color: Red;
    font-weight: bold;
}

/* = Produkt liste
-----------------------------------------------------------------------------*/
.PagingBox {
    display: inline-block;
    margin: 0;
    padding: 3px 6px;
    border: 0 solid #DDDDDD;
    border-radius: 4px;
    text-decoration: none;
}

.TableList {
    float: none;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    border: 0 solid #D8D6CE;
}

.TableList th {
    padding: 5px;
    background-color: inherit;
    font-weight: normal;
}

.TableList .ProduktListProduktBeskrivelse a, .TableList .ProduktListVeilPris a {
    padding: 5px;
    background-color: inherit;
    text-decoration: none;
}

.TableList .ProduktListPris a {
    padding: 5px;
    background-color: inherit;
    text-align: right;
    text-decoration: none;
}

.TableList td {
    padding: 10px;
    border-top: 1px solid #DDDDDD;
    vertical-align: middle;
}

.TableList .ProduktImg {
    margin: 5px;
    padding: 0;
    width: inherit;
    border: 0 solid #D8D6CE;
    cursor: pointer;
}

/* =SiteMap
-----------------------------------------------------------------------------*/
.SiteMapList {
    margin-top: 20px;
}

.SiteMapList .Level0 {
    list-style-position: inside;
}

.SiteMapList .Level0 li {
    list-style-type: none;
    font-weight: bold;
}

.SiteMapList .Level1 {
    border: solid 1px #d8d6ce;
    margin: 10px 20px 20px 20px;
    padding: 0px 0px 10px 10px;
}

.SiteMapList .Level1 li {
    margin-top: 10px;
    font-weight: bold;
}

.SiteMapList .Level2 {
    margin: 10px 30px 40px 20px;
    border: solid 1px #cccccc;
    padding-left: 5px;
    background-color: #ebeae5;
}

.SiteMapList .Level2 li {
    list-style-type: disc;
    padding: 0px 0px 5px 5px;
}

.SiteMapList .Level3 {
    margin-left: 20px;
}

.SiteMapList .Level3 li {
    font-weight: normal;
}

.SiteMapList .Level4 {
    margin-left: 20px;
}

.SiteMapList .Level5 {
    margin-left: 20px;
}

.SiteMapList .Level6 {
    margin-left: 20px;
}

.SiteMapList .Level7 {
    margin-left: 20px;
}

.ButtonLagre {
    margin-top: 15px;
}

table#table_FraTilDato td {
    white-space: nowrap;
    padding: 5px;
}



/* =Retur
-----------------------------------------------------------------------------*/

.CustomerCenterContainer {
    @include toSizeSM() {
        .ReturModul {
            table {
                border-width: 0px;
            }
        }
    }
}

.return-file-size-notice {
    margin-top: 10px;
    display: inline-block;
}


/* =Kunderegistrering rediger konto
--------------------------------------------------------------------------------------------------------------------*/
.InputRed {
    border: solid 1px #c7532c;
    padding: 1px;
}

.InputExtraLarge {
    width: 200px;
}

.InputLarge {
    width: 150px;
}

.InputMediumPoststed {
    width: 60.5%;
}

.InputMedium {
    width: 90px;
}

.InputSmall {
    width: 50px;
}

.InputExtraSmall {
    width: 30%;
}

.InputResizeable {
    width: 95%;
}

.InputResizeableDd {
    width: 98%;
    max-width: 126px;
}

.InputResizeable:disabled {
    background-color: rgb(235, 235, 228);
}

.InputOrgNr {
    width: 30px;
}

.ErrorMargin {
    margin-bottom: 20px;
}

.InputDfsID {
    width: 70px;
}

.Kunderegistrering td {
    padding: 2px;
}

.Kunderegistrering ul {
    margin-left: 25px;
}

.Kunderegistrering .Padding5px {
    padding: 5px;
}

.KunderegistreringTable {
    width: 100%;
    border-left: solid 1px #ebeae5;
    border-right: solid 1px #ebeae5;
}

.KunderegistreringTable td {
    padding: 2px 5px 2px 5px;
}

.KunderegistreringTable .tdText {
    width: 20%;
}

.KunderegistreringTable .tdTextBox {
    width: 30%;
}

.KunderegistreringTable .i-delivery-customer .tdText,
.Kunderegistrering div.i-delivery-customer {
    padding-top: 20px;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    color: #d00000;
}
.Kunderegistrering div.i-delivery-customer{
    text-align: left;
}

.KunderegistreringTable .tdBorderTop {
    border-top: solid 1px #ebeae5;
}

.KunderegistreringTable .tdBorderBottom {
    border-bottom: solid 1px #ebeae5;
    padding-bottom: 10px;
}

.KunderegistreringTable .TdRequired {
    width: 10px;
    border-left: solid 1px #ebeae5;
    border-right: solid 1px #ebeae5;
    text-align: center;
}

.KunderegistreringTable .divTextboxEpostPDF {
    width: 100%;
    padding-top: 4px;
}

.KunderegistreringTable .TdRequiredEpostPDF {
    width: 10px;
    border-left: solid 1px #ebeae5;
    border-right: solid 1px #ebeae5;
    text-align: center;
    padding-top: 15px;
}

.WizzardStepMenu {
    background-color: #ebeae5;
    border: 1px solid #d8d6ce;
    margin: 0px 0px 30px 0px;
}

.WizzardStepMenu .StepText {
    padding: 4px 10px 4px 10px;
    font-weight: bold;
}

.WizzardStepMenu .StepSelected {
    background-color: #d8d6ce;
}

.WizzardStepMenu .img {
    width: 12px;
    padding: 0px;
}

.WizzardStepMenu .link {
    text-decoration: none;
}

.Kunderegistrering .RequiredIcon {
    width: 8px;
    height: 8px;
    display: inline-block;
    color: #cc0000;
    font-size: 18px;
}

.Kunderegistrering .RequiredIcon:before {
    content: "\002a";
}

.MenuCustomerInfo.NoUnderLine {
    font-size: 1.1em;
    text-decoration: underline;
}

.MenuEditAccount.NoUnderLine {
    font-size: 1.1em;
    text-decoration: underline;
}

.MenuChangePassword.NoUnderLine {
    font-size: 1.1em;
    text-decoration: none;

    @include fromSizeSM {
        text-decoration: underline;
    }
}

.MenuShowContacts.NoUnderLine {
    font-size: 1.1em;
    text-decoration: underline;
}

.DeleteMyAccount {
    font-size: 1.1em;
    text-decoration: underline;
}

.MenuShowPriceList.NoUnderLine {
    font-size: 1.1em;
    text-decoration: underline;
}

.MinSideToppInfo {
    margin: 10px 0 0;
}

.InputExtraLarge {
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
}

.InputResizeable {
    width: 95%;
    box-sizing: border-box;
    height: 30px;
    padding: 3px 5px;
}

.InputExtraSmall {
    width: 30%;
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
}

.InputMediumPoststed {
    width: 62.5%;
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
}

.change-password-form input[type="text"], .change-password-form input[type="password"] {
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
    width: 50%;
}

.change-password-form {
    width: 100%;
}

.change-password-form tr > td:first-child {
    width: 25%;
}

.change-password-form tr > td {
    width: 75%;
}

.change-password-form-error-list {
    position: relative;
    left: 40px;
}

.ReturDropDown {
    width: 200px;
    box-sizing: border-box;
    height: 30px;
    margin-bottom: 10px;
}

.ReturTextBox {
    width: 200px;
    box-sizing: border-box;
    height: 30px;
}

.ReturModul .LinkKundesenter.NoUnderLine {
    text-decoration: underline;
}

.PdfLink:link, .PdfLink:visited, .PdfLink:active {
    width: 40px;
    height: 22px;
    box-sizing: border-box;
}

.ReturSmallDropDown {
    width: 50px;
    box-sizing: border-box;
    height: 30px;
}

.TableListRetur select {
    box-sizing: border-box;
    height: 30px;
}

.MultiCase-window input[type=text] {
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
    margin-bottom: 5px;
}

select.TextBoxKundedata {
    box-sizing: border-box;
    height: 30px;
    padding: 5px;
}

input.TextBoxKundedata {
    box-sizing: border-box;
    height: 30px;
    margin-top: 1px;
    padding: 5px;
}

#Table_OrdreTotal {
    float: right;
    position: relative;
    right: 10px;
}

.SummaryTableInnerTable {
    float: right;
    position: relative;
    right: 10px;
}

#divRepeaterOrdrelinje {
    max-width: 100% !important;
    width: 100% !important;
}

#divRepeaterFakturaliste {
    max-width: 100% !important;
    width: 100% !important;
}

#divRepeaterPakkesporliste {
    max-width: 100% !important;
    width: 100% !important;
}

#divRepeaterPakkesporOrdrelinje {
    max-width: 100% !important;
    width: 100% !important;
}

.TableList tr {
    background-color: #ffffff;
}

.TableList .RowColor {
    background-color: #ffffff;
}


/* = Glemt passord
-----------------------------------------------------------------------------*/

@include toSizeSM() {
    .KundeSenterBox table.change-password-form {
        padding: 15px 5px;
        display: block;

        tbody {
            width: 100%;
            display: block;
        }

        tr {
            display: block;
            padding: 5px 0;
        }

        td {
            width: 100%;
            display: block;
        }

        input[type="text"],
        input[type="password"] {
            width: 100%;
        }

        .Button.btn {
            width: 48%;
        }

        .btn.btn-cancel {
            width: 48%;
            border: 1px solid #ccc;
        }
    }
}


/* = Prisliste
-----------------------------------------------------------------------------*/

@include toSizeSM() {
    #KundesenterPrisListe {
        table.kundesenter-table,
        table tbody,
        table tr,
        table td {
            width: 100%;
            display: block;
        }

        td.prislist-table-td {
            padding: 15px 5px !important;
        }

        .kundesenter-accept-content {
            display: block;
            width: 100%;
            margin: 0 !important;
        }

        .kundesenter-accept-wrap {
            display: block;
            width: 100%;
            float: none !important;
            height: auto !important;
            margin: 0 !important;
        }

        .kundesenter-accept-checkbox {
            display: block;
            width: 100%;

            input[type="checkbox"] {
                margin-top: 2px;
            }

            label {
                margin-left: 25px;
                margin-top: -22px;
            }
        }

        table.kundesenter-format {
            display: block;
        }

        .kundesenter-format a {
            text-align: center;
            display: block;
            margin: 15px 0px;
        }
        /* Høyre klikk finnes ikke på mobil og pad */
        .prislist-tip {
            display: none;
        }
    }
}



/* = Orderstatusliste
-----------------------------------------------------------------------------*/

@include toSizeSM() {

    .order-list-top {
        height: auto !important;
    }

    .order-list-space {
        display: none;
    }

    .order-list-header {
        display: none;
    }



    .CustomerCenterContainer .KundeSenterBox table.order-orderlist-table {
        border: none;
        display: block;
        width: 100%;
        padding-bottom: 15px;
    }

    .order-orderlist-td1 {
        width: 100% !important;
        padding: 10px 15px;
        text-align: center;
    }

    .order-orderlist-space {
        height: auto !important;
        margin-top: -26px;
    }

    .ContentPlaceHolderBg.FilterOrder table,
    .ContentPlaceHolderBg.FilterOrder table tr {
        display: block;
        width: 100%;
    }

    .FilterOrder .GaiaRadioPosition {
        display: block;
        width: 100%;
        padding-left: 15px;

        label {
            padding: 6px 15px;
            width: 85%;
        }
    }

    .FilterOrder {
        table,
        tbody,
        tr, td {
            display: block;
            width: 100%;
        }
    }

    .order-filter-td1 {
        h3 {
            margin: 0;
        }

        span {
            text-align: center;
            font-size: 16px;
            display: block;
            width: 100%;
        }
    }

    .order-filter-space {
        display: none;
    }

    .order-filter-bottom {
        height: auto !important;
    }



    .TableList.order-orderlist-panel-table th {
        border-left: 1px solid #ccc;
        width: 20%;
        text-align: center;
    }

    .TableList.order-orderlist-panel-table th:first-child {
        border-left: none;
    }

    .order-orderlist-panel-table .VerticalText.RowColor {
        background-color: #efefef;
    }

    td.order-filter-td2 {
        display: block;
        width: 100%;

        span {
            display: block;
            width: 100%;
        }
    }

    .CustomerCenterContainer .KundeSenterBox table.order-filter-date-table,
    .order-filter-date-table tbody,
    .order-filter-date-table tr {
        display: block;
        width: 100%;
        padding: 0;
        border-top: none;
    }

    .order-filter-date-content {
        border-top: none !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .order-filter-date-info {
        display: none;
    }

    .order-filter-date-cal {
        /*position: absolute !important;*/
        left: 0 !important;
        width: 100% !important;
    }

    .FilterOrder td.order-filter-date-table-td3,
    .FilterOrder td.order-filter-date-table-td35,
    .FilterOrder td.order-filter-date-table-td6 {
        display: none;
    }

    .FilterOrder td.order-filter-date-table-td1,
    .FilterOrder td.order-filter-date-table-td4 {
        display: inline-block;
        width: 25%;
    }

    .FilterOrder td.order-filter-date-table-td2,
    .FilterOrder td.order-filter-date-table-td5 {
        display: inline-block;
        width: 70%;

        input {
            width: 110px !important;
        }
    }

    .order-filter-date-table-td7 .btn {
        width: 100%;
    }

    .order-filter-date-table .hidden {
        display: inline-block !important;
    }





    .CustomerCenterContainer .KundeSenterBox table.order-orderlist-panel-table,
    .order-orderlist-panel-table tbody,
    .order-orderlist-panel-table tr {
        display: block;
        width: 100%;
        padding: 0;
    }

    .order-orderlist-panel-table .hidden {
        display: inline-block !important;
    }

    .TableList td.order-orderlist-panel-td1 {
        display: block;
        font-weight: bold;
    }

    .CustomerCenterContainer .KundeSenterBox td.order-orderlist-panel-td2 {
        width: 100%;
        display: block;
        padding: 0;
        margin: 0;
        border: none;

        a.LinkKundesenter {
            width: 100%;
            display: block;
            text-overflow: ellipsis;
            padding: 35px 15px 75px 15px;
            margin-top: -35px;
            margin-bottom: -80px;
            background: none;
            color: #555;
            border: none;
        }

        a .icon-right-open {
            float: right;
        }
    }

    .TableList td.order-orderlist-panel-td3 {
        width: 100%;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        border: 0;
    }

    .TableList td.order-orderlist-panel-td4 {
        width: 100%;
        display: block;
        border: 0;
        font-weight: bold;
    }


    .btn.btn-default.order-list-back {
        margin: 20px 10px 40px 10px;
        width: 95%;
        display: block;
    }
}



/* =Orderstatus
-----------------------------------------------------------------------------*/

@include toSizeSM {
    .ordrestatus-top {
        height: auto !important;
    }

    h2.ordrestatus-header {
        display: none;
    }

    .ordrestatus-space {
        max-height: 2px;
        display: block;
    }

    table.ordrestatus-table,
    table.ordrestatus-table tbody,
    table.ordrestatus-table tr {
        display: block;
        width: 100%;
    }

    .ordrestatus-table {

        td.OrdreDetaljTD.ordrestatus-adr,
        .OrdreDetaljTD.ordrestatus-del,
        .OrdreDetaljTD.ordrestatus-gen {
            width: 100%;
            display: block;
            border-bottom: 1px solid #ccc;
            border-right: none;
        }
    }

    .CustomerCenterContainer .KundeSenterBox table.ordrestatus-table {
        border: none;
    }

    .ordrestatus-table caption {
        display: none;
    }

    .ordrestatus-tr1 {
        border-left: none;
        border-bottom: 1px solid #ccc;
    }

    td.ordrestatus-shop,
    td.ordrestatus-info {
        background: none;
        vertical-align: baseline;
        width: 100%;
        display: block;
        padding: 0 10px;
        border-right: none !important;

        h3 {
            font-size: 16px;
            margin: 0;
            padding-bottom: 10px;
        }

        span {
            font-size: 16px;
            display: block;
            font-weight: bold;

            &.ordrestatus-txt,
            &.ordrestatus-txt-info {
                display: inline;
            }
        }
    }

    td.ordrestatus-shop-break {
        display: none;
    }

    .CustomerCenterContainer .KundeSenterBox .ordrestatus-table-gen {
        width: 100%;
        display: block;
        border: none;
        padding: 0;
    }

    table.ordrestatus-table-gen {
        padding: 0;

        tbody,
        td,
        tr {
            width: 100%;
            display: block;
            border: none;
        }
    }

    table#ProductList,
    table#ProductList tbody,
    table#ProductList tr {
        padding: 0;
        width: 100%;
        display: block;
    }

    table#ProductList tr.ordrestatus-l-header {
        display: none;
    }

    table#ProductList tr.ordrestatus-l-items-lg {
        display: none;
    }

    table#ProductList tr.ordrestatus-l-items-xs {
        display: block !important;

        &.RowColor {
            background-color: #efefef;
        }

        td {
            width: 100%;
            display: block;
            border: none;
            padding: 2px 10px;
        }

        .ordrestatus-l-td3 {
            display: none;
        }

        .ordrestatus-l-td7 span:last-child {
            font-weight: bold;
        }
    }

    .CustomerCenterContainer .KundeSenterBox {
        table.SummaryTable,
        table tbody,
        table tr,
        table table,
        table table tbody,
        table table tr {
            padding: 0;
            width: 100%;
            display: block;
            border: none;
        }
    }

    table.SummaryTable {
        td.ordrestatus-exp-Top {
            float: left;
            display: block;
            width: 100%;
        }

        .ordrestatus-exp-bottom {
            display: block;
            width: 100%;
        }
    }

    table.ordrestatus-table-exp td {
        display: block;
        width: 100%;
        padding-left: 10px;
    }

    table.ordrestatus-table-exp td.ordrestatus-exp-td2,
    table.ordrestatus-table-exp td.ordrestatus-exp-td3,
    table.ordrestatus-table-exp td.ordrestatus-exp-td5,
    table.ordrestatus-table-exp td.ordrestatus-exp-td7 {
        display: none;
    }

    #Table_OrdreTotal {
        padding-left: 10px !important;
        padding-right: 20px;
        position: initial;
        float: none;
        padding-bottom: 30px;
    }

    .ordrestatus-back,
    .ordrestatus-serial,
    .ordrestatus-track,
    .ordrestatus-delete,
    .ordrestatus-buy {
        display: block;
        width: 100%;
        padding: 2px 10px;

        .btn {
            width: 100%;
        }
    }

    .ButtonBuyKundesenter.btn {
        margin-top: 10px;
    }

    .ordrestatus-error {
        display: block;
        width: 100%;
    }

    #Table_OrdreTotal tr {
        text-align: right;
    }

    #Table_OrdreTotal td {
        display: inline-block;
        margin-right: -4px;

        &.order-line-value{
            padding-left:10px;
        }
    }
}

@include fromSizeSM {
    table#ProductList tr.ordrestatus-l-items-lg {
        display: table-row;
    }
}


/* Se alle kontakter */
.extra-contacts-container {
    @include toSizeSM {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.extra-contacts-white-space {
    @include toSizeSM {
        display: none !important;
    }
}

.extra-contacts-responsive-notice {
    @include toSizeSM {
        display: inline-block !important;
        color: #999;
        font-style: italic;
    }
}

.add-extra-contact-form {
    @include toSizeSM {
        display: none;
    }
}

.extra-contacts-table {

    @include toSizeSM {
        border: none !important;
    }

    tr {
        @include toSizeSM {
            margin-bottom: 10px;
        }
    }

    td {
        @include toSizeSM {
            border: none;
            display: inline-block;
            padding: 3px;
        }
    }

    th {
        @include toSizeSM {
            display: none;
        }
    }
}

.exit-remove-extra-contact {
    @include toSizeSM {
        display: none !important;
    }
}


.extra-contact-first-name,
.extra-contact-last-name {
    @include toSizeSM {
        font-weight: bold;
    }
}

.extra-contact-email {
    @include toSizeSM {
        width: 100%;
    }
}

.extra-contact-is-admin::before {
    @include toSizeSM {
        content: 'Admin: ';
    }
}

.kundesenter-customerinfo {
	padding-top: 10px;
}

.kundesenter-customerinfo-label, .kundesenter-customerinfo-link-pdf, .kundesenter-customerinfo-link-xml {
	font-weight: bold;
}


/* =Ordrehistorikk
--------------------------------------------------------------------------------------------------------------------*/
.OrdreRedColor {
    color: #FF0000;
}

.OrdreGreenColor {
    color: #008000;
}

.OrdreBlueColor {
    color: #0000FF;
}

/* =Customer club
--------------------------------------------------------------------------------------------------------------------*/
.customer-center-customer-club {
    padding-bottom: 15px;
    font-size: larger;
}

.customer-center-customer-club-label-turnover {
    min-width: 175px;
}

.customer-center-customer-club-label-outstanding {
    color: green;
}

.customer-center-customer-club-outstanding {
    color: green;
}
